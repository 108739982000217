<template>
  <q-card v-if="app" class="q-ma-md">
    <q-card-section class="border-bottom q-px-none q-py-xs row items-center">
      <q-legend :label="app.name" :image="app.image || ''"/>

      <span class="text-caption text-weight-bold text-grey-7">
        {{ $t('By') + ' ' + (app.developer || $t('unknown')) }}
      </span>
    </q-card-section>

    <q-card-section class="row q-pa-none" style="min-height: 80vh;">
      <div class="col-8 border-right q-py-sm" style="height: calc(100vh - 80px); overflow: auto;">
        <div v-if="model">
          <div v-if="!model.id && newFormComponents[app.adapter]" class="q-px-sm">
            <component
                :is="newFormComponents[app.adapter]"
                @change="handleChange"
                no-connect-btn
            />
          </div>

          <div v-else-if="model.id && defaultFormComponents[app.adapter]" class="q-px-sm">
            <component
                :is="defaultFormComponents[app.adapter]"
                @change="handleChange"
                no-connect-btn
            />
          </div>

          <!-- <div v-else-if="isDS || !Array.isArray(app.settings)" class="q-px-sm">
            <integration-form
              :integration="app"
              :model="model"
              :is-d-s="isDS"
              @change="handleChange"
            />
          </div> -->

          <q-tabs
              v-else
              v-model="tab"
              dense
              active-color="primary"
              indicator-color="primary"
              align="justify"
              class="text-grey fit"
              narrow-indicator
          >
            <q-tab
                v-for="group in app.settings"
                :key="group.name"
                :name="group.name"
                :label="$t(group.label || 'No name')"
            />
            <q-tab :label="$t('Actions')" name="actions">
            </q-tab>
          </q-tabs>

          <q-separator/>
          <div v-if="authDialogEnabled"
               class="row items-center justify-center text-subtitle1"
               style="min-height: 500px; flex-direction: column;">
            <div>
              {{ $t('You will redirected to the QuickBooks website for sign in to your account!') }}

            </div>
            <div class="row items-center justify-center q-mt-md" style="gap:30px">
              <q-btn
                  color="light-blue-9"
                  label="Discard"
                  size="1rem"
                  class="q-mt-sm"
                  @click="removeItem"
              />
              <q-btn
                  color="light-blue-9"
                  label="Ok"
                  size="1rem"
                  class="q-mt-sm"
                  @click="handleRedirected"
              />
            </div>
            <div class="row items-center justify-center q-mt-md">
              <q-btn
                  color="light-blue-9"
                  label="Shipping plans"
                  size="1rem"
                  class="q-mt-sm"
                  @click="handleShippingPlans"
              />
            </div>
          </div>

          <q-tab-panels v-model="tab" animated keep-alive>
            <q-tab-panel
                v-for="group in app.settings"
                :key="group.name"
                :name="group.name || ''"
            >
              <integration-tab
                  :model="model"
                  :tab="group"
                  @change="handleChange"
              />
            </q-tab-panel>
            <q-tab-panel key="actions" name="actions">
              <div class="row items-center justify-center fit">
                <div class="col-12 text-center">
                  <q-btn
                      color="light-blue-9"
                      label="Renew app authorization"
                      size="1.5rem"
                      class="q-mt-sm"
                      @click="handleAdd"
                  />
                </div>
              </div>
            </q-tab-panel>
          </q-tab-panels>

          <sticky-bottom-header
              :is-loading="isSaveLoading"
              :is-active="hasChange"
              :save-label="$t(model.id ? 'Save' : 'Install')"
              @back="handleDiscard"
              @save="save"
          />
        </div>

        <div v-else class="row items-center justify-center fit">
          <div class="col-12 text-center">
            <div class="row items-center justify-center q-mt-md">
              <q-btn
                  color="light-blue-9"
                  label="Connect new app"
                  size="1.5rem"
                  class="q-mt-sm"
                  @click="handleAdd"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="border-bottom row q-pa-sm items-center">
          <div class="text-subtitle1 text-weight-bold">
            {{ $t('Connections') }}
          </div>

          <div class="col" >
            <search
                dense
                autoset
                is-expandable
                @submit="handleSearch"
            />

          </div>

          <space/>

          <q-btn
              color="light-blue-9"
              icon="add"
              @click="handleAdd"
          />
        </div>

        <q-table
            style="height: calc(100vh - 130px);"
            class="sticky-header-table"
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="items"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="isLoading"
            :filter="filter"
            virtual-scroll
            binary-state-sort
            flat
            @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
                showing
                color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
                :props="props"
                class="clickable"
                @dblclick="onRowClick(props.row)"
            >
              <q-td
                  key="id"
                  :props="props"
                  class="text-subtitle1"
              >
                {{ props.row.id }}
              </q-td>

              <q-td
                  key="name"
                  :props="props"
              >
                {{ props.row.name }}
                <q-popup-edit v-model="props.row.name" auto-save v-slot="scope" style="align-content: center">
                  <q-input type="text" v-model="scope.value" dense autofocus @keyup.enter="handleNameChange(scope.value,props.row)"/>
                </q-popup-edit>
              </q-td>

              <q-td
                  key="created"
                  :props="props"
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>

              <q-td
                  key="state"
                  :props="props"
              >
                <q-toggle
                    color="light-blue-9"
                    :model-value="props.row.state === 'active'"
                    @update:model-value="handleStateChange(props.row)"
                />
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import IntegrationForm from '../../components/integration-wizard/IntegrationForm.vue'
import IntegrationTab from '../../components/integration-wizard/IntegrationTab.vue'
import Search from '../../components/search/Search'

// Utils
import _ from 'lodash'
import { buildQuery } from '../../utils/query-utils'
import { convertSourceIntegration } from '../../helpers/helpers'
import { convertEmbedded, difference } from '../../helpers/request-helpers'
import { HALApiService } from '@/apps/app/services'

export default {
  name: 'AppQuickBooks',
  components: {
    IntegrationForm,
    IntegrationTab,
    Search
  },
  data () {
    return {
      tab: '',
      newFormComponents: {
        'Orderadmin\\Integrations\\Shopify\\Service\\Integration': 'shopify-form'
      },
      defaultFormComponents: {},
      cleanModel: null,
      model: null,
      items: [],
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        }
      ],
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      isLoading: false,
      filter: '',
      filters: [
        { type: 'eq', field: 'state', value: 'active' }
      ],
      deliveryService: null,
      isSaveLoading: false,
      hasChange: false,
      authDialogEnabled: false
    }
  },
  computed: {
    ...mapGetters([
      'app',
      'appOptions'
    ]),
    isDS () {
      return this.app && this.app.adapter.includes('DeliveryServices')
    }
  },
  mounted () {
    if (!this.app) {
      return this.loadApp(this.$route.params.id)
          .then(() => {
            this.onRequest({ pagination: { page: 1, per_page: 25 } })
          })
    }

    this.onRequest({ pagination: { page: 1, per_page: 25 } })
  },
  unmounted () {
    this.setApp(null)
  },
  methods: {
    ...mapActions([
      'loadApp'
    ]),
    ...mapMutations([
      'setApp',
      'addWarningNotification'
    ]),
  redirectedToQuickBooks () {
      return HALApiService.get(`/apps/quickbooks/settings?app=app&host=${window.location.host}` + (this.model.id ? `&source=${this.model.id}` : ``))
          .then(item => {
            window.location.href = item.button
            return item
          })
    },
    handleRedirected () {
      this.redirectedToQuickBooks()
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    handleChange (update) {
      this.model = update
      this.hasChange = true
    },
    handleAdd () {
      this.setItem({
        _embedded: {}
      })

      this.authDialogEnabled = true
    },
    onRowClick (item) {
      this.setItem(item)
    },
    handleNameChange (result, item) {
      return Promise.resolve(this.isDS)
          .then(isDS => {
            return isDS
                ? this.$service.deliveryServiceIntegration.save({ name: result }, item.id)
                : this.$service.iSource.save({ name: result }, item.id)
          })
          .then(item => {
            this.replaceItem(item)
          })
    },
    handleStateChange (item) {
      const invertedState = {
        active: 'inactive',
        inactive: 'active'
      }

      const state = invertedState[item.state]

      return Promise.resolve(this.isDS)
          .then(isDS => {
            return isDS
                ? this.$service.deliveryServiceIntegration.save({ state }, item.id)
                : this.$service.iSource.save({ state }, item.id)
          })
          .then(item => {
            this.replaceItem(item)
          })
    },
    getSIntegrations (query) {
      query.filter.push({ type: 'eq', field: 'handler', value: this.app.extId })

      return this.$service.iSource.getAll(query)
    },
    loadDeliveryService () {
      const query = {
        per_page: 5,
        page: 1,
        filter: [
          { type: 'eq', field: 'extId', value: this.app.extId }
        ]
      }

      return this.$service.deliveryService.getAll(query)
          .then(({ items }) => {
            this.deliveryService = items[0]
            return this.deliveryService
          })
    },
    loadDSItems (query) {
      return Promise.resolve(!!this.deliveryService)
          .then(hasDS => {
            return hasDS
                ? this.deliveryService
                : this.loadDeliveryService()
          })
          .then(deliveryService => {
            query.filter.push({ type: 'eq', field: 'deliveryService', value: deliveryService.id })

            return this.$service.deliveryServiceIntegration.getAll(query)
          })
    },
    loadSItems (query) {
      query.filter.push({ type: 'eq', field: 'handler', value: this.app.extId })

      return this.$service.iSource.getAll(query)
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query.filter = [...this.filters]
      query['order-by'] = [
        { type: 'field', field: 'created', direction: 'desc' }
      ]
      query.sort = { id: 'desc' }

      this.isLoading = true
      return Promise.resolve()
          .then(() => {
            return this.isDS
                ? this.loadDSItems(query)
                : this.loadSItems(query)
          })
          .then(({ items, totalPages, page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            this.items = items

            return { items, totalPages, page, totalItems }
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    setItem (item) {
      this.model = _.cloneDeep(item)
      this.cleanModel = item && item.id
          ? _.cloneDeep(item)
          : null
      this.hasChange = false
      this.tab = ''
    },
    removeItem () {
      this.model = ''
    },
    replaceItem (item) {
      let isFounded = false

      this.items = this.items.map(x => {
        if (x.id == item.id) {
          isFounded = true
          return item
        }

        return x
      })

      if (!isFounded) {
        this.items = [item, ...this.items]
      }

      return this.items
    },
    save () {
      this.isSaveLoading = true

      return Promise.resolve(this.isDS)
          .then(isDS => {
            return isDS
                ? this.saveDSIntegration()
                : this.saveSIntegration()
          })
          .then(data => {
            this.setItem(data)
            this.replaceItem(data)
          })
          .finally(() => {
            this.isSaveLoading = false
          })
    },
    saveDSIntegration () {
      const ignoredFields = ['deliveryService', 'sender']

      let data = this.model.id
          ? convertEmbedded(difference(this.model, this.cleanModel), ignoredFields)
          : convertEmbedded(this.model)

      if (Object.keys(data).length === 0) {
        this.addWarningNotification('No changes!')
        return Promise.resolve(this.model)
      }

      if (data.settings) {
        data = convertSourceIntegration(data)
      }

      return this.$service.deliveryServiceIntegration.save(data, this.model.id)
    },
    saveSIntegration () {
      if (this.model.id) {
        let data = convertEmbedded(difference(this.model, this.cleanModel))

        if (data.settings) {
          data = convertSourceIntegration(data)
        }

        if (Object.keys(data).length === 0) {
          this.addWarningNotification('No changes!')
          return Promise.resolve(this.model)
        }

        return this.$service.iSource.save(data, this.model.id)
      }

      let data = { ...this.model }
      data = convertSourceIntegration(data)
      return this.$service.iSource.install(this.app.id, data)
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
  }
}
</script>
